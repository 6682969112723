import { Col } from "react-bootstrap"

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbox">

        <a href={link} target="_blank" rel="noreferrer">
          <img src={imgUrl} alt="something"/>
          <div className="proj-txt">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </a>
      </div>
    </Col>
  )
}