import { useState, useEffect } from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import logo from '../assets/img/logo.svg'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, seScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        seScrolled(true);
      } else {
        seScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeLink === "home" ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("home")}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activeLink === "skills" ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("skills")} >Skills</Nav.Link>
            <Nav.Link href="#projects" className={activeLink === "projects" ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("projects")}>Projects</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div class="social-icon">
              <a href="https://api.whatsapp.com/send/?phone=79163167110&text&type=phone_number&app_absent=0" target="_blank"
              rel="noreferrer"
              ><img src={navIcon1} alt="icon1something"/></a>
              <a href="https://t.me/Dadayablin" target="_blank"
              rel="noreferrer"
              ><img src={navIcon2} alt="icon2something"/></a>
              <a href="https://github.com/galimovruslan21" target="_blank"
              rel="noreferrer"><img src={navIcon3} alt="icone3something"/></a>
            </div>
            <a class="text-reset text-decoration-none" href="https://hh.ru/applicant/resumes/view?resume=42882fb6ff03fc51560039ed1f366561457042" target="_blank"
            rel="noreferrer"
            >
            <button class="vvd" onClick={() => console.log('connect')}>
            Let's Connect
            </button>
            </a>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}