import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
          <div className="skill-box">
            <h2>
              Skills
            </h2>
            <p>Мой первый опыт программирования произошёл в Школе 21. Я познакомился с языком C. Во время обучения в школе я овладел базовым пониманием этого языка, работой с терминалом, </p>
          </div>
          <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="item1">
              <svg viewBox="0 0 36 36" class="circular-chart blue">
                <path class="circle-bg"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray="30, 100"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text text x="18" y="20.35" class="percentage">30%</text>
              </svg>
              <h5>JS And JS Frameworks</h5>
            </div>
            <div className="item2">
            <svg viewBox="0 0 36 36" class="circular-chart blue">
                <path class="circle-bg"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray="80, 100"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text text x="18" y="20.35" class="percentage">80%</text>
              </svg>
              <h5>Basic Web Development(HTML,CSS)</h5>
            </div>
            <div className="item3">
            <svg viewBox="0 0 36 36" class="circular-chart blue">
                <path class="circle-bg"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray="35, 100"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text text x="18" y="20.35" class="percentage">35%</text>
              </svg>
              <h5>Web Development</h5>
            </div>
            <div className="item4">
            <svg viewBox="0 0 36 36" class="circular-chart blue">
                <path class="circle-bg"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray="100, 100"
                  d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text text x="18" y="20.35" class="percentage">100%</text>
              </svg>
              <h5>Motivation And Desire To Learn</h5>
            </div>
          </Carousel>

          </Col>
        </Row>
      </Container>
          <img className="background-image-left" alt="something" src={colorSharp} />
    </section>
  )
}