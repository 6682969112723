import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg"
import 'animate.css';
import TrackVisibility from "react-on-screen";

/* eslint-disable no-unused-vars */
export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = [ "Learning Web Dev", "School21 Student" ];
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(150);
  const [index, setIndex] = useState(1);
  const period = 1000;


  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(150);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7} >
            <TrackVisibility>
            {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animated__fadeIn" : ""}>
              <span className="tagline">Welcome To My Portfolio</span>
              <h1>{`Hi! I'm Ruslan Galimov. I'm`}<span className="wrap"> {text}</span></h1>
              <p>Привет, меня зовут Руслан и я изучаю веб-девелопмент и фронт-энд направление. Я обучаюсь в Школе 21 от Сбербанка. Большую часть своего времени я посвящаю школьным проектам и их сдаче. Свободное же время я посвящаю изучению верстки сайтов, JS и улучшению качества UI/UX в своих проектах.</p>
              <button onClick={() => console.log(`connect`)}><a class="text-reset text-decoration-none" href="https://hh.ru/applicant/resumes/view?resume=42882fb6ff03fc51560039ed1f366561457042" target="_blank" rel="noreferrer">Let's Connect<ArrowRightCircle size={25}/></a></button>
            </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5} >
            <img src={headerImg} alt="something" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}