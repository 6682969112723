import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import ProjectImage1 from "../assets/img/bootstrap-site.png";
import ProjectImage2 from "../assets/img/preview.png";
import ProjectImage3 from  "../assets/img/mui-react-dark.png";


export const Projects = () => {

  const projects = [
    {
      title: "Bootstrap site",
      description: "Click on this text to visit site",
      imgUrl: ProjectImage1,
      link: "https://bootstrap-site.pages.dev/",
    },
    {
      title: "Typescript site",
      description: "Click on this text to visit site",
      imgUrl: ProjectImage2,
      link: "https://typescript-gym-40x.pages.dev",
    },
    {
      title: "React application",
      description: "Click on this text to visit site",
      imgUrl: ProjectImage3,
      link: "https://mui-react-589.pages.dev",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
          <h2>Projects</h2>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam quia iusto ex iure earum eaque.</p>
            <Tab.Container id="project-tabs" defaultActiveKey="first">
              <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                <Nav.Item>
                  <Nav.Link eventKey="first">Tab-One</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Work In Progress</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Work In Progress</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {
                      projects.map((project, index) => {
                        return (
                          <ProjectCard 
                          background-size="cover"
                          key={index}
                          {...project}
                          />
                        )
                      })
                    }
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">Work In Progress</Tab.Pane>
                <Tab.Pane eventKey="third">Work In Progress</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
  
    </section>
  )
}